.LoginRegisterWrapper {
  height: 100%;
  width: 100%;
  display: block;
}

.LoginRegisterWrapper .hintText {
  height: 30px;
  text-align: center;
  color: #211646;
  margin-top: 6px;
  font-size: 14px;
  clear: both;
  float: none;
}

.LoginRegisterWrapper .LoginWrapper {
  z-index: 2;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

.LoginRegisterWrapper .LoginWrapper .loginBtn {
  width: 90%;
  display: block;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  background-color: #30A5FF;
  color: #FFF;
  text-align: center;
  margin-left: 5%;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper {
  height: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  background-color: #F0F3F4;
  /*box-shadow: 0 13px 10px -8px rgba(0,0,0,0.3) inset;*/
}

.LoginRegisterWrapper .LoginWrapper .downWrapper .twoLineWrapper {
  background-color: #FFF;
  border-top: 1px solid #E4E7F0;
  border-bottom: 1px solid #E4E7F0;
  margin-top: 24px;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper .textUnderline {
  display: block;
  width: 90%;
  margin-left: 5%;
  height: 1px;
  background-color: #E4E7F0;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper .mobileLine img {
  width: 12px;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper .mobileLine input {
  width: 200px;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper .checkCodeLine img {
  width: 14px;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper .checkCodeLine input {
  width: 140px;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper .mobileLine, .LoginRegisterWrapper .LoginWrapper .downWrapper .checkCodeLine {
  background-color: #FFF;
  height: 50px;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper .mobileLine .textHint, .LoginRegisterWrapper .LoginWrapper .downWrapper .checkCodeLine .textHint {
  float: left;
  color: #52565C;
  margin-left: 8%;
  margin-top: 14px;
  min-width: 46px;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper .mobileLine input, .LoginRegisterWrapper .LoginWrapper .downWrapper .checkCodeLine input {
  float: left;
  margin-left: 6px;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  font-size: 16px;
  height: 34px;
  margin-top: 7px;
  padding-left: 10px;
  color: #211646;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper .checkCodeLine .checkCodeBtn {
  float: right;
  box-sizing: content-box;
  opacity: 0.6;
  color: #30A5FF;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  background-color: #FFF;
  border: 1px solid #30A5FF;
  border-radius: 6px;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 14px;
  margin-right: 5%;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper .loginSwitch {
  margin-top: 16px;
  font-size: 13px;
  text-align: center;
  width: 100%;
  height: 16px;
  line-height: 16px;
  color: #30A5FF;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #CDCED2;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #CDCED2;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #CDCED2;
}

.LoginRegisterWrapper .LoginWrapper .downWrapper input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #CDCED2;
}

.LoginRegisterWrapper .BasicInfoWrapper {
  z-index: 2;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

.LoginRegisterWrapper .BasicInfoWrapper .loginBtn {
  width: 90%;
  display: block;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  background-color: #D6CDD8;
  color: #211646;
  text-align: center;
  margin-left: 5%;
}

.LoginRegisterWrapper .BasicInfoWrapper .textUnderline {
  display: block;
  width: 90%;
  margin-left: 5%;
  height: 1px;
  background-color: rgba(232, 229, 225, 0.3);
  margin-top: 18px;
}

.LoginRegisterWrapper .BasicInfoWrapper .upperWrapper {
  height: 70%;
}

.LoginRegisterWrapper .BasicInfoWrapper .pageTitle {
  color: #FFF;
  height: 24%;
  margin-top: 20%;
  line-height: 25px;
  font-size: 18px;
  margin-left: 7%;
  letter-spacing: 2px;
}

.LoginRegisterWrapper .BasicInfoWrapper .lineHint {
  float: left;
  letter-spacing: 1px;
  height: 24px;
  line-height: 24px;
  margin-top: 13px;
}

.LoginRegisterWrapper .BasicInfoWrapper .birthdayText {
  margin-top: 13px;
  height: 24px;
  line-height: 24px;
  margin-left: 24px;
  float: left;
}

.LoginRegisterWrapper .BasicInfoWrapper .onChooseArrow {
  margin-top: 13px;
  float: right;
  margin-right: 5%;
  transform: scaleX(1.6);
  transform-origin: left;
  opacity: 0.8;
}

.LoginRegisterWrapper .BasicInfoWrapper .radioOption {
  float: left;
  color: #FFF;
  margin-top: 13px;
  padding-left: 6px;
  padding-right: 40px;
}

.LoginRegisterWrapper .BasicInfoWrapper input {
  float: left;
  margin-left: 6px;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  font-size: 16px;
  height: 34px;
  margin-top: 7px;
  padding-left: 10px;
  color: #FFF;
}

.LoginRegisterWrapper .BasicInfoWrapper .radioOptionIcon {
  display: block;
  background-color: #FFF;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  overflow: hidden;
  line-height: 18px;
  border-radius: 9px;
  float: left;
  margin-top: 16px;
}

.LoginRegisterWrapper .BasicInfoWrapper .radioOptionIcon .innerCircle {
  background-color: #FFB700;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  display: block;
  margin-top: 3px;
  margin-left: 3px;
}

.LoginRegisterWrapper .BasicInfoWrapper .nameLine, .LoginRegisterWrapper .BasicInfoWrapper .genderLine, .LoginRegisterWrapper .BasicInfoWrapper .birthdayLine {
  padding-top: 4px;
  float: none;
  clear: both;
  height: 38px;
  font-size: 16px;
  color: #FFF;
  box-sizing: border-box;
  padding-left: 5%;
}

.LoginRegisterWrapper .BasicInfoWrapper .hintText2 {
  height: 50px;
  text-align: center;
  color: #FFB700;
  margin-top: 24px;
  font-size: 14px;
  clear: both;
  float: none;
}

.xboluoDatePicker {
  background-color: #211646;
}

.xboluoDatePicker .am-picker-popup-item {
  color: #FFF;
}

.xboluoDatePicker .am-picker-popup-header {
  background-image: linear-gradient(to bottom, #999, #999, transparent, transparent);
}

.xboluoDatePicker .am-picker-col-mask {
  background-image: linear-gradient(to bottom, rgba(33, 22, 70, 0.95), rgba(33, 22, 70, 0.6)), linear-gradient(to top, rgba(33, 22, 70, 0.95), rgba(33, 22, 70, 0.6));
}

.xboluoDatePicker .am-picker-col-item {
  color: #FFB700;
}
