body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: sans-serif;
}

body .nothinggg, html .nothinggg {
  position: relative;
}

#root {
  height: 100%;
}

a.am-button.am-button-primary:hover {
  color: #FFF;
}

a.am-button:hover {
  color: #000;
}
